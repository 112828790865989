.description {
  ol {
    @apply list-decimal;
  }

  ul {
    @apply list-disc;
  }

  a {
    @apply text-blue-500 hover:underline;
  }
}
