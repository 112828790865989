.editor {
  & :global(.tox) {
    @apply rounded-lg border-primary-light;
  }
}

.editor_error {
  & :global(.tox) {
    @apply border-red-600 text-red-600;
  }
}
